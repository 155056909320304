import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http'
import { ForgotPasswordDTO } from '../side-by-side-template/model/ForgotPasswordDTO';
import { ChangePasswordDTO } from '../side-by-side-template/model/ChangePasswordDTO';
import { ConfirmRegisterDTO } from '../side-by-side-template/model/ConfirmRegisterDTO';
import { RegisterDTO } from '../side-by-side-template/model/RegisterDTO';
import { DomainService } from './domain-service';
import { ConfirmSmsRegisterDTO } from 'app/side-by-side-template/model/ConfirmSmsRegisterDTO';
import { CommonsUtils } from 'app/commons/CommonsUtils';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de Usuarios
 * @author Iecisa
 */
export class UserService {

    counterVisibility: Boolean = false;
    latestRegister: RegisterDTO;
    code: string;

    constructor( private domainService: DomainService, private http: HttpClient) { }

    public setCounterVisibility(value: any) {
        this.counterVisibility = value;
    }

    public getCounterVisibility(): Boolean {
        return this.counterVisibility;
    }

    public setLatestRegister(value: any) {
        this.latestRegister = value;
    }

    public getLatestRegister(): RegisterDTO {
        return this.latestRegister;
    }

    public setCode(value: any) {
        this.code = value;
    }

    public getCode(): string {
        return this.code;
    }

    onChangePassword(changePassword: ChangePasswordDTO): Promise<any> {

        const url = this.domainService.backendUrlWithContext + '/users/changePassword';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('email', changePassword.email.toLowerCase());
        form.append('uuid', changePassword.uuid);
        form.append('password', changePassword.password);
        form.append('password2', changePassword.password2);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();

    }

    onForgotPassword(forgotPassword: ForgotPasswordDTO): Promise<any> {

        const url = this.domainService.backendUrlWithContext + '/users/forgotPassword';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('email', forgotPassword.email.toLowerCase());

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
        
    }

    onConfirmSmsRegister(confirmSmsRegister: ConfirmSmsRegisterDTO): Promise<any> {

        const secret = confirmSmsRegister.number;

        const url = this.domainService.backendUrlWithContext + '/users/smsConfirm';
        const form = new FormData();
        const headers = CommonsUtils.headersBearer();

        form.append('code', confirmSmsRegister.code);
        form.append('secret', secret);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();

    }

    onConfirmRegister(confirmRegister: ConfirmRegisterDTO): Promise<any> {

        const url = this.domainService.backendUrlWithContext + '/users/confirm';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('uuid', confirmRegister.uuid);
        form.append('email', confirmRegister.email.toLowerCase());
        form.append('password', confirmRegister.password);
        form.append('password2', confirmRegister.password2);

        if (confirmRegister.termsAndConditions) {
            form.append('consents', 'TERMS_AND_CONDITIONS');
        }

        if (confirmRegister.dataPolicy) {
            form.append('consents', 'DATA_POLICY');
        }

        return this.http.post<any>(url, form, {headers:headers}).toPromise();

    }

    onRegister(register: RegisterDTO, isreinsured: any): Promise<any> {

        const url = this.domainService.backendUrlWithContext + '/users/register';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('documentType', register.documentType);
        form.append('documentNumber', register.documentNumber.toLocaleUpperCase());
        form.append('email', register.email);
        //form.append('policy', register.policy);
        register.policy ?  form.append('policy', register.policy) : form.append('event', register.event);
        form.append('mobilePhone', register.mobilePhone);
        form.append('mobilePhonePrefix', register.mobilePhonePrefix[0].id);

        if(isreinsured){
            form.append('entity', isreinsured);
        }

        if ((undefined !== register.legalServicesAdvertising) && (null !== register.legalServicesAdvertising)) {
            if (register.legalServicesAdvertising) {
                form.append('consents', 'LEGAL_SERVICES_ADVERTISING');
            }
        }

        if ((undefined !== register.insuranceAdvertising) && (null !== register.insuranceAdvertising)) {
            if (register.insuranceAdvertising) {
                form.append('consents', 'INSURANCE_ADVERTISING');
            }
        }

        return this.http.post<any>(url, form, {headers:headers}).toPromise();

    }
}
