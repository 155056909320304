import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation, state, style, animate } from '@angular/animations';
import { AuthService } from 'app/security/auth.service';
import { Router } from '@angular/router';
import { DomainService } from '../../../services/domain-service';

@Component({
    selector: 'app-selected-entity',
    templateUrl: './selected-entity.component.html',
    styleUrls: ['./selected-entity.component.scss'],
    animations: [
        trigger('fadeInUp', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0, transform: 'translateY(5%)' }), animate(450)]),
            transition(':leave', animate(600, style({ opacity: 0 })))
        ])
    ]
})
export class SelectedEntityComponent implements OnInit {

    entities: any[];

    constructor(private authService: AuthService, private router: Router, private domainService: DomainService) { }

    ngOnInit() {
        if (undefined !== this.authService.getUser()) {
            this.entities = this.authService.getUser().entities;
        }
    }

    onSelect(key) {
        this.authService.setSelectedEntity(key);
        this.router.navigate(['/main']);
    }

    getStyle(key): any {
        const pngUrl = this.domainService.wordpressUrl +
            '/wp-content/themes/frontend/resources/assets/images/logos/' + key + '.png';
        return {
            'background': 'url(' + pngUrl + ')  no-repeat center',
            'background-size': 'contain',
            'min-width': '50px',
            'width': '50px',
            'min-height': '50px',
            'height': '50px',
            'margin': '0 1rem',
            'background-color': 'white',
            'border-radius': '50px'
        }
    }
}
