import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-confirm-sms-register-page',
    templateUrl: './confirm-sms-register-page.component.html',
    styleUrls: ['./confirm-sms-register-page.component.scss']
})
export class ConfirmSmsRegisterPageComponent {

    resend: Boolean = false;
    reinsured;
    innominado;
    parametros;

    constructor(private router: Router,
        private route: ActivatedRoute) {
            this.parametros = this.route.snapshot.params
            if(this.parametros['nameless']){
                this.innominado = this.parametros['nameless'];
            } else {
                this.reinsured = this.parametros['reinsured'];
            }
     }

    onConfirmSmsRegisterSubmit(event) {
        if (event) {
            if ((undefined !== event.response) && (null !== event.response) &&
                (undefined !== event.response.address) && (null !== event.response.address)) {
                    if(this.reinsured){
                        this.router.navigateByUrl(
                            '/confirm-register?address=' + event.response.address + '&uuid=' + event.response.uuid + '&reinsured=' + this.reinsured);
                    } else {
                        this.router.navigateByUrl(
                            '/confirm-register?address=' + event.response.address + '&uuid=' + event.response.uuid);
                    }

            }
        }
    }

    onResendSmsRegisterSubmit(event) {
        this.resend = true;
    }

    onReceiveNewSmsCode(event) {
        this.resend = false;
    }

}
