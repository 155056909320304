/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selected-entity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../commons/components/contact-line/contact-line.component.ngfactory";
import * as i4 from "../../../commons/components/contact-line/contact-line.component";
import * as i5 from "./selected-entity.component";
import * as i6 from "../../../security/auth.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/domain-service";
var styles_SelectedEntityComponent = [i0.styles];
var RenderType_SelectedEntityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectedEntityComponent, data: { "animation": [{ type: 7, name: "fadeInUp", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(5%)" }, offset: null }, { type: 4, styles: null, timings: 450 }], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 600 }, options: null }], options: {} }] } });
export { RenderType_SelectedEntityComponent as RenderType_SelectedEntityComponent };
function View_SelectedEntityComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "company-ind"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "logo"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStyle(_v.context.$implicit.key); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.value; _ck(_v, 6, 0, currVal_1); }); }
export function View_SelectedEntityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "selector no-text-select"]], [[24, "@fadeInUp", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Selecciona la aseguradora que deseas consultar "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "company"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectedEntityComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-contact-line", [], null, null, null, i3.View_ContactLineComponent_0, i3.RenderType_ContactLineComponent)), i1.ɵdid(7, 49152, null, 0, i4.ContactLineComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.entities; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_SelectedEntityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-selected-entity", [], null, null, null, View_SelectedEntityComponent_0, RenderType_SelectedEntityComponent)), i1.ɵdid(1, 114688, null, 0, i5.SelectedEntityComponent, [i6.AuthService, i7.Router, i8.DomainService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectedEntityComponentNgFactory = i1.ɵccf("app-selected-entity", i5.SelectedEntityComponent, View_SelectedEntityComponent_Host_0, {}, {}, []);
export { SelectedEntityComponentNgFactory as SelectedEntityComponentNgFactory };
