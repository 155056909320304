import { HttpClient } from '@angular/common/http';
import { DomainService } from './domain-service';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import * as i0 from "@angular/core";
import * as i1 from "./domain-service";
import * as i2 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(domainService, http) {
        this.domainService = domainService;
        this.http = http;
        this.counterVisibility = false;
    }
    UserService.prototype.setCounterVisibility = function (value) {
        this.counterVisibility = value;
    };
    UserService.prototype.getCounterVisibility = function () {
        return this.counterVisibility;
    };
    UserService.prototype.setLatestRegister = function (value) {
        this.latestRegister = value;
    };
    UserService.prototype.getLatestRegister = function () {
        return this.latestRegister;
    };
    UserService.prototype.setCode = function (value) {
        this.code = value;
    };
    UserService.prototype.getCode = function () {
        return this.code;
    };
    UserService.prototype.onChangePassword = function (changePassword) {
        var url = this.domainService.backendUrlWithContext + '/users/changePassword';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('email', changePassword.email.toLowerCase());
        form.append('uuid', changePassword.uuid);
        form.append('password', changePassword.password);
        form.append('password2', changePassword.password2);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    UserService.prototype.onForgotPassword = function (forgotPassword) {
        var url = this.domainService.backendUrlWithContext + '/users/forgotPassword';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('email', forgotPassword.email.toLowerCase());
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    UserService.prototype.onConfirmSmsRegister = function (confirmSmsRegister) {
        var secret = confirmSmsRegister.number;
        var url = this.domainService.backendUrlWithContext + '/users/smsConfirm';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('code', confirmSmsRegister.code);
        form.append('secret', secret);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    UserService.prototype.onConfirmRegister = function (confirmRegister) {
        var url = this.domainService.backendUrlWithContext + '/users/confirm';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('uuid', confirmRegister.uuid);
        form.append('email', confirmRegister.email.toLowerCase());
        form.append('password', confirmRegister.password);
        form.append('password2', confirmRegister.password2);
        if (confirmRegister.termsAndConditions) {
            form.append('consents', 'TERMS_AND_CONDITIONS');
        }
        if (confirmRegister.dataPolicy) {
            form.append('consents', 'DATA_POLICY');
        }
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    UserService.prototype.onRegister = function (register, isreinsured) {
        var url = this.domainService.backendUrlWithContext + '/users/register';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('documentType', register.documentType);
        form.append('documentNumber', register.documentNumber.toLocaleUpperCase());
        form.append('email', register.email);
        //form.append('policy', register.policy);
        register.policy ? form.append('policy', register.policy) : form.append('event', register.event);
        form.append('mobilePhone', register.mobilePhone);
        form.append('mobilePhonePrefix', register.mobilePhonePrefix[0].id);
        if (isreinsured) {
            form.append('entity', isreinsured);
        }
        if ((undefined !== register.legalServicesAdvertising) && (null !== register.legalServicesAdvertising)) {
            if (register.legalServicesAdvertising) {
                form.append('consents', 'LEGAL_SERVICES_ADVERTISING');
            }
        }
        if ((undefined !== register.insuranceAdvertising) && (null !== register.insuranceAdvertising)) {
            if (register.insuranceAdvertising) {
                form.append('consents', 'INSURANCE_ADVERTISING');
            }
        }
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.DomainService), i0.inject(i2.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
